"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.partition = void 0;
function partition(array, predicate) {
    const unmatched = [];
    const matched = [];
    for (const item of array) {
        if (predicate(item)) {
            matched.push(item);
        }
        else {
            unmatched.push(item);
        }
    }
    return [matched, unmatched];
}
exports.partition = partition;
