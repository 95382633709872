import {Type} from '@sinclair/typebox';

/**
 * Definition for custom styles. If used as a Ref value must be included in a
 * `definitions` block of the outermost `Type.Object`.
 *
 * @example Usage in a `Type.Object`
 *
 * ```ts
 * Type.Object({styleAttr})
 * ```
 *
 * @example Usage as a Ref
 *
 * ```ts
 * Type.Object(
 * {myStyles: Type.Optional(Type.Ref(styleAttr))},
 * {definitions: {styleAttr}}
 * );
 * ```
 */
export const styleAttr = Type.Optional(
  Type.String({
    $id: '#/definitions/styleAttr',
    title: 'Custom Styles',
    default: '',
    description:
      'Use `.lcd-preview-only & { /* styles */ }` to apply styles specific to the layout editor.',
  })
);

export const styleAttrUi = {
  styleAttr: {
    'ui:widget': 'modalTextareaWidget',
    'ui:options': {
      buttonTitle: 'Edit',
      editor: 'CodeMirror',
    },
  },
};
