import {type RefObject, useEffect, useRef} from 'react';
import {useManagedRef} from './useManagedRef';

/**
 * Creates a `<script>` element and injects it into the `<body>` of the
 * document. When the `useExternalScript` hook "unmounts" the `<script>` element
 * is removed. Passing a new `src` or `id` will inject a _new_ script element,
 * passing a new `onload` function will replace the `onload` associated with the
 * existing `<script>` element.
 */
export function useExternalScript(
  options: UseExternalScriptOptions
): RefObject<Readonly<HTMLScriptElement> | undefined> {
  const element = useRef<HTMLScriptElement>();
  const onunloadRef = useManagedRef(options.onunload);
  useEffect(() => {
    if (document.getElementById(options.id) !== null) {
      return;
    }
    const onunload = onunloadRef.current;
    const script = document.createElement('script');
    script.id = options.id;
    script.src = options.src;
    script.async = true;
    if (typeof options.integrity === 'string') {
      script.integrity = options.integrity;
    }
    if (typeof options.crossOrigin === 'string') {
      script.crossOrigin = options.crossOrigin;
    }
    document.body.appendChild(script);
    element.current = script;
    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
      element.current = undefined;
      if (typeof onunload === 'function') {
        onunload();
      }
    };
  }, [
    onunloadRef,
    options.crossOrigin,
    options.id,
    options.integrity,
    options.src,
  ]);
  // Manage the `onload` function separately because it is more likely to change
  // between renders/calls
  useEffect(() => {
    const script = element.current;
    if (typeof script === 'undefined') {
      return;
    }
    if (typeof options.onload === 'function') {
      script.onload = options.onload;
    }
  }, [options.onload]);
  return element;
}

interface UseExternalScriptOptions
  extends Pick<HTMLScriptElement, 'src' | 'id'>,
    Partial<Pick<HTMLScriptElement, 'integrity' | 'crossOrigin' | 'onload'>> {
  /** Callback to execute when the `<script>` element is removed */
  onunload?: () => void;
}
